import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule  } from 'ngx-toastr';
import { ErrorInterceptor } from '@modules/core/http-interceptors/error-interceptor';
import { CookieService } from 'ngx-cookie-service';
import * as authServices from '../modules/auth/services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, NgxSpinnerModule, AppRoutingModule, HttpClientModule,ToastrModule.forRoot(),BrowserAnimationsModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },CookieService, ...authServices.services],
    bootstrap: [AppComponent],
})
export class AppModule {}
